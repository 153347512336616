<template>
  <section class="tratament-nou history-modal flex"
           @mousedown="_clickModal">
    <BaseForm @submit="handleSubmit">
      <!-- DATEPICKER -->
      <validation-provider rules="required" v-slot="{ errors }">
        <div class="relative">
          <i class="absolute left0 top0 flex-center" v-html="svg.calendar">
          </i>
          <datepicker
            v-model="form.createdAt"
            :class="{ 'invalid-input': errors[0] }"
            :monday-first="true"
            :language="$data.ro"
            :format="_customFormatter"
            placeholder="data"
            name="uniquename"
          />
        </div>
      </validation-provider>

      <!-- DINTI MULTISELECT -->
      <div class="mtop12px relative z99">
        <multiselect
          v-model="dinti"
          :multiple="true"
          :options="dintiList"
          @input="handleDintiSelect"
          placeholder="dinti"
        >
          <span slot="noResult"></span>
        </multiselect>
      </div>
    </BaseForm>
  </section>
</template>

<script>
  import { historyModal } from '@/mixins/history-modal';
  import { mapState } from 'vuex';
  export default {
    name: 'TratamentNou',
    mixins: [historyModal],
    data () {
      return {
        form: {
          type: 'tratament',
          createdAt: new Date(),
          denumire: undefined,
          dinte: [],
          pret: undefined,
          pics: []
        },
        dinti: []
      }
    },
    computed: {
      ...mapState({
        svg: 'svg',
        dintiList: 'dinti'
      })
    },
    methods: {
      handleDintiSelect (dintiArray) {
        const pretTratament = this.form.dinte.length
          ? +this.form.pret / this.form.dinte.length
          : +this.form.pret;
        this.form.dinte = dintiArray;
        this.form.pret &&
        (this.form.pret = dintiArray.length
          ? pretTratament * dintiArray.length
          : pretTratament);
        !dintiArray.length &&
        this.form.pret &&
        (this.form.pret = pretTratament);
      },
      handleSubmit () {

      }
    }
  };
</script>

<style lang="less">
  .tratament-nou {
  }
</style>
